import { Component } from 'react';

import './styles.css';

class LandingPage extends Component {
  render() {
    return (
      <div id="landing">
        <div id="landing-title-container">
          <h1>BEHIND THE DEMANDS</h1>
        </div>
      </div>
    )
  }
}

export default LandingPage;
